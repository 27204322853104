$(function() {

    if ($('#map').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map", {
                center: [47.214899, 39.715758],
                zoom: 15,
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
            }, {
              preset: 'islands#icon',
              iconColor: '#0095b6'
            });
            
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };
    
        ymaps.ready(init);
    }

    if ($('#map2').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map2", {
                center: [47.117360, 39.418955],
                zoom: 15,
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
            }, {
                preset: 'islands#icon',
                iconColor: '#0095b6'
            });

            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };

        ymaps.ready(init);
    }

    if ($('#map3').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map3", {
                center: [46.359184, 48.038416],
                zoom: 15,
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
            }, {
              preset: 'islands#icon',
              iconColor: '#0095b6'
            });
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };
    
        ymaps.ready(init);
    }
});